<template>
  <div class="booking">
    <v-row class="w-100">
      <v-col cols="12" :md="filterType == 'grid' ? 6 : 4">
        <list-items
          @filterType="filterTypeHandler"
          :filterType="filterType"
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          type="booking"
          :loading="loading"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="filterType == 'grid' ? 6 : 8">
        <!-- head section -->
        <div class="img-cover">
          <img
            width="100%"
            height="250"
            class="object-cover radius-5"
            v-if="paramItem && paramItem.coverImage"
            :src="paramItem.coverImage.url"
            alt=""
          />
          <img
            height="250"
            class="w-100 object-cover"
            v-else
            src="http://ncs.cd.gov.mn/wp-content/themes/icetheme/assets/images/no-image-small.png"
            alt=""
          />
        </div>
        <div
          class="list-head d-flex w-100 justify-space-between align-center pt-5 pb-3"
        >
          <span class="gray--text" style="width: 90%" v-if="paramItem">{{
            paramItem.title
          }}</span>
          <!-- <div class="d-flex">
            <base-select class="mx-1" placeholder="Activity"> </base-select>
            <base-select class="mx-1" placeholder="Activity Date">
            </base-select>
          </div> -->
        </div>
        <!-- loading table -->
        <v-data-table
          :headers="headers"
          :items="[{}]"
          item-key="_id"
          class="users-table elevation-0 mt-4"
          v-if="loadingBookings"
        >
          <template v-slot:item>
            <tr>
              <td v-for="(item, i) in [1, 1, 1, 1]" :key="i">
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- table -->
        <v-data-table
          v-if="!loadingBookings"
          :headers="headers"
          :items="bookings"
          item-key="_id"
          hide-default-footer
          mobile-breakpoint="0"
          class="users-table elevation-0 mt-4"
        >
          <!-- name & email -->
          <template v-slot:item.name="{ item }">
            <span class="d-block font-13 white-grey--text" v-if="item.userId"> {{
              item.userId.name
            }}</span>
          </template>

          <!-- phone -->
          <template v-slot:item.phone="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              item.userId.phone
            }}</span>
          </template>

          <!-- view -->
          <template v-slot:item.view>
            <v-icon @click="$router.push('/bookingId/' + paramID)"
              >mdi-eye</v-icon
            >
          </template>

          <!-- avatar -->
          <template v-slot:item.avatar="{ item }">
            <div class="my-2">
              <img
                v-if="item.userId && item.userId.picture"
                class="user-avatar"
                :src="item.userId.picture.url"
                alt=""
              />
              <img v-else class="user-avatar" :src="avatar" alt="" />
            </div>
          </template>

          <!-- book to -->
          <template v-slot:item.book_to="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              $moment(item.endDate).format("l") +
              " " +
              $moment(item.endDate).format("LT")
            }}</span>
          </template>
          <!-- last login -->
          <template v-slot:item.book_from="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              $moment(item.startDate).format("l") +
              " " +
              $moment(item.startDate).format("LT")
            }}</span>
          </template>

          <!-- create time -->
          <template v-slot:item.booked_at="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              $moment(item.createdAt).format("l") +
              " " +
              $moment(item.createdAt).format("LT")
            }}</span>
          </template>
        </v-data-table>
        <div class="pagination-count text-center" v-if="totalTable > 10">
          <v-pagination
            v-model="page"
            @input="fetchItemByID"
            :length="totalFilterTable"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" content-class="white">
      <booking-user-details
        :itemView="itemView"
        :paramItem="paramItem"
        @close="dialog = false"
      ></booking-user-details>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/activity/ListItems";
import BookingUserDetails from "../../components/dialogs/BookingUserDetails.vue";
export default {
  components: {
    ListItems,
    BookingUserDetails,
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    paramItem: { newPricing: {} },
    paramID: "",
    filterType: "grid",
    listItems: [],

    headers: [
      {
        text: "AVATAR",
        align: "start",
        value: "avatar",
        width: "100px",
      },
      { text: "NAME", value: "name", width: "200px" },
      { text: "Book to", value: "book_to", width: "200px",  },
      { text: "Book from", value: "book_from", width: "200px",  },
      { text: "Booked at", value: "booked_at", width: "200px",  },
      { text: "VIEW", value: "view", width: "100px" },
    ],
    bookings: [],
    dialog: false,
    itemView: { payment: {}, userId: {}, picture: {}, guest: [], options: [] },
    total: 0,
    totalTable: 0,
    page: 1,
    loading: true,
    loadingBookings: true,
  }),
  watch: {
    "$route.params": {
      async handler() {
        // fetch item by id
        await this.fetchList()
        this.paramID = this.$route.params.id;
        if (!this.paramID) {
          this.paramItem = this.listItems[0];
        } else {
          this.paramID = this.$route.params.id;
          this.paramItem = this.listItems.filter(
            (item) => item._id == this.paramID
          )[0];
        }
        await this.fetchItemByID();
      },
    },
  },
  computed: {
    totalFilterTable() {
      return Math.ceil(+(this.totalTable / 10));
    },
  },
  methods: {
    filterTypeHandler(type) {
      this.filterType = type;
    },
    async viewItem(item) {
      this.dialog = true;
      this.itemView = item;
    },
    async fetchList(number = 1) {
      this.loading = true;
      let { data } = await this.$axios.get(
        `activity/all/new?pageSize=10&pageNumber=${number}`
      );
      this.loading = false;
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
    },
    async fetchItemByID(number = 1) {
      this.loadingBookings = true;
      let { data } = await this.$axios.get(
        `/activityInstance/all/new/activity/${this.paramID}?pageSize=10&pageNumber=${number}`
      );
      this.loadingBookings = false;

      if (data) {
        this.bookings = data.data;
        this.totalTable = data.count;
      }
    },
  },
  async created() {
    await this.fetchList();
    // fetch item by id
    this.paramID = this.$route.params.id;
    if (!this.paramID) {
      this.paramItem = this.listItems[0];
      this.paramID = this.paramItem._id;
    } else {
      this.paramID = this.$route.params.id;
      this.paramItem = this.listItems.filter(
        (item) => item._id == this.paramID
      )[0];
    }
    await this.fetchItemByID();
  },
};
</script>

<style lang="scss" scoped>
.booking {
  background: white;
}
</style>
