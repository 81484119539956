<template>
  <div>
    <div class="b-bottom px-5 py-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <img
          v-if="itemView.userId && itemView.userId.picture"
          class="user-avatar"
          :src="itemView.userId.picture.url"
          alt=""
        />
        <img v-else class="user-avatar" :src="avatar" alt="" />
        <span class="d-inline-block ml-2 font-13 white-grey--text">{{
          itemView.userId.name
        }}</span>
      </div>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div class="py-5 px-5 b-bottom">
      <v-row>
        <!-- people -->
        <v-col cols="4" class="py-1" v-if="itemView.guest.length > 0">
          <span class="font-14 black-grey--text">
            people ({{ itemView.guest.length }})
          </span>
          <div v-for="(guest, i) in itemView.guest" :key="i">
            <span class="font-13 white-grey--text"> {{ guest.name }}</span>
          </div>
        </v-col>
        <!-- pets -->
        <v-col cols="3" class="py-1">
          <span class="font-14 black-grey--text"> Pets: </span>
          <div>
            <span class="font-13 white-grey--text"> Yes</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- name -->
        <v-col cols="12" class="py-1">
          <span class="font-14 white-grey--text"> Experience name: </span>
          <span class="font-14 error--text">{{ paramItem.title }}</span>
        </v-col>
        <!-- date -->
        <v-col cols="12" class="py-1">
          <span class="font-14 white-grey--text"> Experience date: </span>
          <span class="font-14 error--text" v-if="itemView.startDate">{{
            itemView.startDate.slice(0, 10)
          }}</span>
        </v-col>
        <v-col cols="12" class="py-1 mb-4" v-if="itemView.options.length > 0">
          <span class="font-14 white-grey--text d-block mb-2"> Extras </span>
          <div
            class="blue py-1 px-3 radius-5 d-inline-block mr-3"
            v-for="(option, i) in itemView.options"
            :key="i"
          >
            <span class="font-14 white--text"> {{ option.description }}</span>
          </div>
        </v-col>
        <!-- booked on -->
        <v-col cols="12" class="py-1">
          <span class="font-14 white-grey--text"> Booked on: </span>
          <span class="font-14 black--text font-500">{{
            $moment(itemView.created_at).format("l") +
            " " +
            $moment(itemView.created_at).format("LT")
          }}</span>
        </v-col>
        <!-- total payment -->
        <v-col cols="12" class="py-1">
          <span class="font-14 white-grey--text"> Total payment: </span>
          <span class="font-14 black--text font-500"
            >{{
              (
                +paramItem.rate * paramItem.newPricing.startPriceInUsd
              ).toLocaleString("en")
            }}
            LEB/p</span
          >
        </v-col>
        <!-- Payment Method: -->
        <v-col cols="12" class="py-1">
          <span class="font-14 white-grey--text"> Payment Method: </span>
          <span class="font-14 black--text font-500" v-if="itemView.payment"
            >{{ itemView.payment.paymentType }}
          </span>
        </v-col>
        <!-- chat -->
        <v-col cols="12" class="py-1">
          <span class="font-14 black--text font-500"> Chat </span>
          <base-textarea
            v-model="chat"
            placeholder="Ask for more details about this task..."
            :rules="[]"
          ></base-textarea>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn dark color="darkblue"> Send Message</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemView: { type: Object, default: () => {} },
    paramItem: { type: Object, default: () => {} },
  },
  data:() => ({
    avatar: require("@/assets/images/global/avatar.png"),
    chat: "",

  })
};
</script>

<style></style>
